// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: #f8f9fa;
    background: linear-gradient(to right, #00FF00, #0000FF);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 70px;
  }
  
  .nav {
    display: flex;
    align-items: center;
    height: 100%; /* Ensure the navbar takes up full height of the header */
  }
  
  .nav-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    margin-left: 1.5rem;
  }
  
  .nav-item a {
    text-decoration: none;
    color: #333;
    font-size: 1.2rem;
    font-weight: italic;
  }
  
  .nav-item a:hover {
    color: #007bff;
  }`, "",{"version":3,"sources":["webpack://./src/Projects.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,uDAAuD;IACvD,wCAAwC;IACxC,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY,EAAE,yDAAyD;EACzE;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 2rem;\r\n    background-color: #f8f9fa;\r\n    background: linear-gradient(to right, #00FF00, #0000FF);\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    z-index: 1000;\r\n    height: 70px;\r\n  }\r\n  \r\n  .nav {\r\n    display: flex;\r\n    align-items: center;\r\n    height: 100%; /* Ensure the navbar takes up full height of the header */\r\n  }\r\n  \r\n  .nav-list {\r\n    display: flex;\r\n    list-style: none;\r\n    margin: 0;\r\n    padding: 0;\r\n  }\r\n  \r\n  .nav-item {\r\n    margin-left: 1.5rem;\r\n  }\r\n  \r\n  .nav-item a {\r\n    text-decoration: none;\r\n    color: #333;\r\n    font-size: 1.2rem;\r\n    font-weight: italic;\r\n  }\r\n  \r\n  .nav-item a:hover {\r\n    color: #007bff;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
