// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliding-images {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
  }
  
  .sliding-images img {
    width: 100%;
    height: auto;
  }
  
  
 `, "",{"version":3,"sources":["webpack://./src/SlidingImages.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".sliding-images {\r\n    width: 80%;\r\n    margin: 0 auto;\r\n    padding-top: 20px;\r\n  }\r\n  \r\n  .sliding-images img {\r\n    width: 100%;\r\n    height: auto;\r\n  }\r\n  \r\n  \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
