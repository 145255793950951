// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dashboard.css */
.container {
    margin-top: 50px;
  }
  
  .d-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB","sourcesContent":["/* Dashboard.css */\r\n.container {\r\n    margin-top: 50px;\r\n  }\r\n  \r\n  .d-flex {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-bottom: 30px;\r\n  }\r\n  \r\n  h1 {\r\n    font-size: 2rem;\r\n    color: #333;\r\n  }\r\n  \r\n  .btn-primary {\r\n    background-color: #007bff;\r\n    border-color: #007bff;\r\n  }\r\n  \r\n  .btn-primary:hover {\r\n    background-color: #0056b3;\r\n    border-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
